import {
  InspectionType,
  useInspection
} from '@/services/providers/InspectionContext'

const inspectionTypeToUIMapper = {
  BR: {
    '5asec': '5àsec',
    acompanhamentoObraMM: 'Medição de Obra - MM',
    aditivo: 'Vistoria de Aditivo',
    auditoriaLoja: 'Auditoria de Loja',
    avaliacao: 'Vistoria de Avaliação',
    avaliacaoHousi: 'Visita Técnica Housi',
    cashme: 'CashMe',
    cautelar: 'Cautelar',
    cautelarAterpa: 'Cautelar - ATERPA',
    cautelarQueirozGalvao: 'Cautelar - ENGETEC',
    cautelarCCInfra: 'Cautelar - CCInfra',
    checklistSamsung: 'Vistoria de Auditoria Samsung',
    conferencia: 'Vistoria de Conferência',
    conferenciaFinalDeObra: 'Conferência de Final de Obra',
    entrada: 'Vistoria de Entrada',
    entradaBHomy: 'Vistoria de Entrada B.Homy',
    entradaLight: 'Vistoria de Entrada Lite',
    entrega: 'Vistoria de Entrega',
    finalDeObra: 'Vistoria de Final de Obra',
    inspecaoPredial: 'Vistoria de Inspeção Predial',
    inventario: 'Vistoria de Inventário',
    orcamento: 'Vistoria de Orçamento',
    predial: 'Predial',
    predialO2: 'Predial O2',
    preVistoriaBHomy: 'Pré-Vistoria B.Homy',
    saida: 'Vistoria de Saída',
    tombamento: 'Vistoria de Tombamento',
    transferencia: 'Vistoria de Transferência',
    vialaser: 'Vialaser',
    visita: 'Visita',
    entradaCurtaTemporada: 'Entrada Curta Temporada',
    saidaCurtaTemporada: 'Saída Curta Temporada'
  },
  PT: {
    '5asec': '5àsec',
    acompanhamentoObraMM: 'Medição de Obra - MM',
    aditivo: 'Inspeção de Aditivo',
    auditoriaLoja: 'Auditoria de Loja',
    avaliacao: 'Inspeção de Avaliação',
    avaliacaoHousi: 'Visita Técnica Housi',
    cashme: 'CashMe',
    cautelar: 'Cautelar',
    cautelarAterpa: 'Cautelar - ATERPA',
    cautelarQueirozGalvao: 'Cautelar - ENGETEC',
    cautelarCCInfra: 'Cautelar - CCInfra',
    checklistSamsung: 'Inspeção de Auditoria Samsung',
    conferencia: 'Inspeção de Conferência',
    conferenciaFinalDeObra: 'Conferência de Final de Obra',
    entrada: 'Inspeção de Entrada',
    entradaBHomy: 'Inspeção de Entrada B.Homy',
    entradaLight: 'Inspeção de Entrada Lite',
    entrega: 'Inspeção de Entrega',
    finalDeObra: 'Inspeção de Final de Obra',
    inspecaoPredial: 'Inspeção Predial',
    inventario: 'Inspeção de Inventário',
    orcamento: 'Inspeção de Orçamento',
    predial: 'Predial',
    predialO2: 'Predial O2',
    preVistoriaBHomy: 'Pré-Inspeção B.Homy',
    saida: 'Inspeção de Final de Contrato',
    tombamento: 'Inspeção de Tombamento',
    transferencia: 'Inspeção de Compra/Venda',
    vialaser: 'Vialaser',
    visita: 'Visita',
    entradaCurtaTemporada: 'Entrada Curta Temporada',
    saidaCurtaTemporada: 'Saída Curta Temporada'
  }
}

export const inspectionTypeToUI = (type: InspectionType) => {
  const { inspection } = useInspection()

  return inspectionTypeToUIMapper?.[inspection?.country || 'BR']?.[type] || ''
}
